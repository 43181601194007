* {
	margin: 0;
	padding: 0;
}
.kite {
	position: absolute;
	width: 100px;	height: 100px;
	background: rgb(255, 0, 119);
	transform: rotate(45deg);
	top: "-20%";
	left: 0;
	bottom: 0;
	right: 0;
	margin: auto;
    z-index: -1;
	animation: flying 10s ease-in-out infinite;
}
.kite:before, .kite:after {
  content: '';
  position: absolute;
}

.kite:before {
	top: 50%;	left: -20%;
	width: 141%;	border-top: 1px solid #c3b3b3;
	transform: rotate(45deg);
}

.kite:after {
	top: 0;	left: 0;
	width: 100px;	height: 100px;
	border-top-left-radius: 100%;
	border-left: 1px solid #c3b3b3;
	border-top: 1px solid #c3b3b3;
}

.tail {
	position: relative;
	top: 95px;	left: 85px;
	width: 0;	height: 0;	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
	border-bottom: 20px solid black;
	transform: rotate(-45deg);
	overflow: hidden;
}  
.string {
	position: absolute;
	top: 50%;
	right: 60%;
	width: 300px;
	height: 500px;
	border-right: 2px solid #5b13d8;
	border-radius: 5% 0 100% 5%;
	transform-origin: top;
	transform: rotate(10deg);
}
.string::after {
	position: absolute;
	bottom: calc(100% - 1px);
	left: 100%;
	width: 35px;
	height: 45px;
	content: '';
	border: 1px solid #ffffff;
	
	border-top-color: transparent;
	border-right-color: transparent;
	transform-origin: bottom left;
	transform: rotate(-20deg);
}


@keyframes flying {
	0% , 100% {
		top: 0%;
		left: 0%;
	}
	10% {
		top: -10%;
		left: 5%;
	}
	20% {
		top: 10%;
		left: -10%;
	}
	30% {
		top: -20%;
		left: -20%;
	}
	40% {
		top: -25%;
		left: -13%;
	}
	25% {
		top: -15%;
		left: -5%;
	}
	50% {
		top: -20%;
		left: 10%;
	}
	60% {
		top: -21%;
		left: 25%;
	}
	70% {
		top: -22%;
		left: 30%;
	}
	75% {
		top: -25%;
		left: 25%;
	}
	80% {
		top: -20%;
		left: 15%;
	}
}
